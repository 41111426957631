import Vue from "vue";
import VueHead from "vue-head";
import { VTooltip, VPopover, VClosePopover } from "v-tooltip";

import { clickOutside } from "@ui/helpers/_directives/clickOutside";
import fixNumber from "@ui/helpers/_functions/fix-number";
import fixDate from "@ui/helpers/_functions/fix-date";
import fixUtcDate from "@ui/helpers/_functions/fix-utc-date";

import router from "@/router";
import store from "@/store";
import { EventBus } from "@/services/event-bus";
import features from "@/services/features";

// eslint-disable-next-line import/extensions
import "@ui/node_modules/cropperjs/dist/cropper.css";

import AcmPluginFactory from "./services/auth/plugin";

import i18n from "./i18n";
import { localize } from "./helpers/_functions/i18n/dictionaryPathResolver";

require(`@ui/css/scss/style--${__DEMO__ ? "demo" : __PLATFORM__}.scss`);

/**
 * Vue environment configuration
 */
if (__APP_ENV__ !== "production") {
  Vue.config.productionTip = true;
  Vue.config.devtools = true;
}

Vue.config.productionTip = false;

Vue.use(VueHead);

/**
 * Global directives/filters
 */
Vue.directive("click-outside", clickOutside);
Vue.filter("fixNumber", fixNumber);
Vue.filter("fixDate", fixDate);
Vue.filter("fixUtcDate", fixUtcDate);

/**
 * V-Tooltip
 */
Vue.directive("tooltip", VTooltip);
Vue.directive("close-popover", VClosePopover);
Vue.component("v-popover", VPopover);

/**
 * Authenfication events (bus)
 */
EventBus.$on("login", () => {
  AcmPluginFactory().then(AcmPlugin => {
    Vue.use(AcmPlugin);
  });
});

EventBus.$on("logout", () => {
  Vue.prototype.$user = {
    isLoggedIn: false
  };
});

// TARGET
Vue.prototype.$isTargetPrescriber = __TARGET__ === "prescriber";
Vue.prototype.$isTargetAdmin = __TARGET__ === "admin";
Vue.prototype.$isTargetExtranet = __TARGET__ === "extranet";

// CLIENT
Vue.prototype.$isBastide = __PLATFORM__ === "bastide";
Vue.prototype.$isBastideDiabete = __PLATFORM__ === "bastide-diabete";
Vue.prototype.$isLorair = __PLATFORM__ === "lorair";
Vue.prototype.$isIntus = __PLATFORM__ === "intus";
Vue.prototype.$isMedpro = __PLATFORM__ === "medpro";

/**
 * Feature flags
 */
Vue.prototype.$features = features;

/**
 * Unregister all service workers
 */
if (window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    if (registrations.length) {
      Promise.all(registrations.map(registration => registration.unregister())).then(() => {
        window.location.reload();
      });
    }
  });
}

/**
 * Empty CacheStorage caches
 */
if (window.caches) {
  caches.keys().then(keys => {
    if (keys.length) {
      Promise.all(keys.map(key => caches.delete(key))).then(() => {
        window.location.reload();
      });
    }
  });
}

/* Global Dictionary Function */
Vue.prototype.localize = localize;

const createApp = (App, settings) => {
  if (!process.env.VUE_APP_I18N_LOCALE) {
    console.error("VUE_APP_I18N_LOCALE not defined");
  }

  /* Global settings */
  Vue.prototype.$settings = settings;
  store.dispatch("appInfos/setDefaultLocale", process.env.VUE_APP_I18N_LOCALE);
  store.dispatch("appInfos/setSupportedLocales", Object.keys(settings.supportedLocales));

  /* Global app */
  Vue.prototype.$appInfo = {
    titleApp: __DEMO__ ? "Demo Env" : settings.titleApp,
    headOffice: __DEMO__ ? "Here will be your head office address" : settings.headOffice,
    name: __DEMO__ ? "Demo" : settings.name,
    longName: __DEMO__ ? "Demonstration Environment" : settings.longName,
    extranetAddresseeName: __DEMO__ ? "Agence démo" : settings.extranetAddresseeName
  };

  Vue.prototype.$defaultDivisionId = settings.defaultDivisionId || "R";

  /* Set global title app */
  document.title = __DEMO__ ? "Demo" : settings.titleApp ?? "";

  /* We have to wrap Vue init inside this factory to wait for it to configure acmPlugin before rendering Vue content as it is async */
  return AcmPluginFactory().then(AcmPlugin => {
    Vue.use(AcmPlugin);

    new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount("#app");
  });
};

export default createApp;
