module.exports = {
  offlinePdf: true,
  communityEntityInterventions: true,
  authBan: true,
  synchroPatient: true,
  synchroStocks: true,
  ordoclic: true,
  addProductByScan: true,
  addProductManually: true,
  stockHandler: true,
  support: true,
  interventions: true,
  patientSignature: true,
  deliveryNote: true,
  planification: {
    global: true,
    onInterventionAchieved: true,
    onContractCreation: true,
    actionButtons: true
  },
  riskAnalysis: true,
  prescriberAccount: true,
  maintenance: true,
  messaging: true,
  baseDocumentaire: true,
  communityTour: true,
  questionnaires: true,
  sms: true,
  interventionReport: true,
  telesuiviAlerts: true
};
