import moment from "moment";

import { fetchPatient, fetchTelesuivi } from "@/modules/common/_api";

const state = {
  patient: {},
  telesuivi: []
};
const getters = {
  patient: state => state.patient,
  telesuivi: state => state.telesuivi
};

const mutations = {
  SET_PATIENT: (state, patient) => {
    state.patient = patient;
  },
  SET_TELESUIVI: (state, telesuivi) => {
    state.telesuivi = telesuivi;
  }
};

const actions = {
  fetchPatient(context, patientId) {
    return new Promise((resolve, reject) => {
      fetchPatient(patientId)
        .then(res => {
          if (res.data?.body) {
            context.commit("SET_PATIENT", res.data.body);
          }

          context.dispatch("fetchTelesuivi", patientId);
          return resolve();
        })
        .catch(errSetPatient => reject(errSetPatient));
    });
  },
  fetchTelesuivi(context, patientId) {
    return new Promise((resolve, reject) => {
      fetchTelesuivi({
        query: {
          patientId,
          startDate: moment().subtract(1, "year").startOf("isoWeek").toISOString(),
          endDate: moment().toISOString()
        }
      })
        .then(res => {
          context.commit("SET_TELESUIVI", res && res.data ? res.data.body : []);
          resolve();
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {});
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
