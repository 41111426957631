/**
 * Rights dictionary to be used as an enum in the backend and front-end of the app,
 * and as a base to set rights migrations as well, as the "Rights" collection can only be updated by us
 */

const mapRights = require("./mapRights");

const patients = {
  type: "Patients",
  rights: {
    CREATE_PATIENT: {
      label: "Créer un patient",
      _id: "create_patient"
    },
    CREATE_PATIENT_GLOBALLY: {
      label: "Créer un patient pour n'importe quel pôle",
      _id: "create_patient_globally"
    },
    CREATE_PATIENT_RESTRICTED: {
      label: "Créer un patient uniquement pour un des pôles de l'utilisateur",
      _id: "create_patient_restricted"
    },
    ACCESS_PATIENT: {
      label: "Accéder à un patient",
      _id: "access_patient"
    },
    DISABLE_SESSION_PATIENT: {
      label: "Supprimer les sessions d'un patient",
      _id: "disable_session_patient"
    },
    EDIT_PATIENT: {
      label: "Mettre à jour un patient",
      _id: "edit_patient"
    },
    EDIT_PATIENT_RESTRICTED: {
      label: "Mettre à jour un patient uniquement pour un des pôles de l'utilisateur",
      _id: "edit_patient_restricted"
    },
    EDIT_PATIENT_GLOBALLY: {
      label: "Mettre à jour un patient pour n'importe quel pôle",
      _id: "edit_patient_globally"
    },
    EDIT_FACTURATION_PATIENT: {
      label: "Mettre à jour les données de facturation d'un patient",
      _id: "edit_facturation_patient"
    },
    EDIT_CONTRACT_CONTACT_INFORMATIONS: {
      label: "Mettre à jour les données de contact d'un patient",
      _id: "edit_contract_contact_informations"
    },
    ARCHIVE_PATIENT: {
      label: "Archiver un patient",
      _id: "archive_patient"
    },
    BAN_PATIENT: {
      label: "Bloque le compte d'un patient",
      _id: "ban_patient"
    },
    ACCESS_PATIENT_FEEDBACK: {
      label: "Accéder aux commentaires et messages laissés par un patient",
      _id: "access_patient_feedback"
    },
    SEND_MESSAGE_FROM_PATIENT_SHEET: {
      label: "Envoyer des messages depuis la fiche patient",
      _id: "send_message_from_patient_sheet"
    },
    ACCESS_PATIENT_RENEWAL_FILE: {
      label: "Accéder à un fichier de renouvellement patient",
      _id: "access_patient_renewal_file"
    },
    GENERATE_PATIENT_EXIT_ATTESTATION: {
      label: "Génerer une attestation de sortie du territoire pour un patient",
      _id: "generate_patient_exit_attestation"
    },
    EDIT_IAH_INITIAL: {
      label: "Changer la valeur IAH initiale d'un patient",
      _id: "edit_iah_initial"
    },
    FORCE_PLANIFICATION: {
      label: "Force la planification pour le patient",
      _id: "force_planification"
    },
    RESET_PASSWORD_PATIENT: {
      label: "Reset le mot de passe du patient",
      _id: "reset_password_patient"
    },
    SYNCHRONIZE_PATIENT: {
      label: "Synchoniser le patient",
      _id: "synchronize_patient"
    },
    PATIENTS_FILTER_BY_POLE: {
      label: "Filtrer par pôle dans la liste des patients",
      _id: "patients_filter_by_pole"
    },
    CREATE_EXTRANET_ACCOUNT: {
      label: "Créer le compte extranet d'un patient",
      _id: "create_extranet_account"
    },
    UPDATE_PATIENT_HSAT: {
      label: "Ajouter un enregistrement d'étude de sommeil d'un patient",
      _id: "update_patient_hsat"
    }
  }
};

module.exports.patients = patients;
module.exports.patientsRights = mapRights(patients);

const contracts = {
  type: "Contrats",
  rights: {
    CREATE_CONTRACT: {
      label: "Créer un contrat",
      _id: "create_contract"
    },
    CREATE_CONTRACT_RESTRICTED: {
      label: "Créer un contrat uniquement pour un des pôles de l'utilisateur",
      _id: "create_contract_restricted"
    },
    CREATE_CONTRACT_GLOBALLY: {
      label: "Créer un contrat pour n'importe quel pôle",
      _id: "create_contract_globally"
    },
    EDIT_CONTRACT: {
      label: "Mettre à jour un contrat",
      _id: "edit_contract"
    },
    ARCHIVE_CONTRACT: {
      label: "Archiver un contrat",
      _id: "archive_contract"
    },
    SIGN_DOCUMENT: {
      label: "Signer le renouvellement du contrat",
      _id: "sign_document"
    },
    CHECK_INITIAL_RENEWAL: {
      label: "Mettre à jour le traitement de la DAP initiale",
      _id: "check_initial_renewal"
    },
    DELETE_RENOU: {
      label: "Supprimer le dernier renouvellement du contrat",
      _id: "delete_renou"
    },
    DEACTIVATE_CONTRACT_GLOBALLY: {
      label: "Désactiver le contract de n'importe quel patient",
      _id: "deactivate_contract_globally"
    },
    DEACTIVATE_CONTRACT_RESTRICTED: {
      label: "Désactiver le contrat d'un patient lié au pôle de l'utilisateur uniquement",
      _id: "deactivate_contract_restricted"
    }
  }
};

module.exports.contracts = contracts;
module.exports.contractsRights = mapRights(contracts);

const alerts = {
  type: "Alertes",
  rights: {
    ACCESS_ALERT: {
      label: "Accéder à une alerte",
      _id: "access_alert"
    },
    EDIT_ALERT: {
      label: "Mettre à jour une alerte",
      _id: "edit_alert"
    },
    ARCHIVE_ALERT: {
      label: "Archiver une alerte",
      _id: "archive_alert"
    }
  }
};

module.exports.alerts = alerts;
module.exports.alertsRights = mapRights(alerts);

const alertsTemplates = {
  type: "Alertes",
  rights: {
    CREATE_ALERT_TEMPLATE: {
      label: "Créer un gabarit d'alerte",
      _id: "create_alert_template"
    },
    ACCESS_ALERT_TEMPLATE: {
      label: "Accéder à un gabarit d'alerte",
      _id: "access_alert_template"
    },
    EDIT_ALERT_TEMPLATE: {
      label: "Mettre à jour un gabarit d'alerte",
      _id: "edit_alert_template"
    },
    ARCHIVE_ALERT_TEMPLATE: {
      label: "Archiver un gabarit d'alerte",
      _id: "archive_alert_template"
    },
    EDIT_GENERAL_ALERT_TEMPLATE: {
      label: "Mettre à jour un gabarit d'alerte général",
      _id: "edit_general_alert_template"
    },
    CREATE_GENERAL_ALERT_TEMPLATE: {
      label: "Créer un gabarit d'alerte général",
      _id: "create_general_alert_template"
    },
    ARCHIVE_GENERAL_ALERT_TEMPLATE: {
      label: "Archiver un gabarit d'alerte général",
      _id: "archive_general_alert_template"
    }
  }
};

module.exports.alertsTemplates = alertsTemplates;
module.exports.alertsTemplatesRights = mapRights(alertsTemplates);

const editorialContents = {
  type: "Contenus éditoriaux",
  rights: {
    CREATE_EDITORIAL_CONTENTS: {
      label: "Créer un contenu éditorial",
      _id: "create_editorial_contents"
    },
    ACCESS_EDITORIAL_CONTENTS: {
      label: "Accéder à un contenu éditorial",
      _id: "access_editorial_contents"
    },
    EDIT_EDITORIAL_CONTENTS: {
      label: "Mettre à jour un contenu éditorial",
      _id: "edit_editorial_contents"
    },
    ARCHIVE_EDITORIAL_CONTENTS: {
      label: "Archiver un contenu éditorial",
      _id: "archive_editorial_contents"
    }
  }
};

module.exports.editorialContents = editorialContents;
module.exports.editorialContentsRights = mapRights(editorialContents);

const messages = {
  type: "Contenus éditoriaux",
  rights: {
    CREATE_MESSAGE: {
      label: "Créer un message",
      _id: "create_message"
    },
    ACCESS_MESSAGE: {
      label: "Accéder à un message",
      _id: "access_message"
    },
    RECEIVE_EXTRANET_MESSAGE: {
      label: "Recevoir les messages de l'extranet",
      _id: "receive_extranet_message"
    }
  }
};

module.exports.messages = messages;
module.exports.messagesRights = mapRights(messages);

const prescribers = {
  type: "Prescripteurs",
  rights: {
    CREATE_PRESCRIBER: {
      label: "Créer un prescripteur",
      _id: "create_prescriber"
    },
    ACCESS_PRESCRIBER: {
      label: "Accéder à un prescripteur",
      _id: "access_prescriber"
    },
    EDIT_PRESCRIBER: {
      label: "Editer un prescripteur",
      _id: "edit_prescriber"
    },
    EDIT_PRESCRIBER_GLOBALLY: {
      label: "Editer un prescripteur pour n'importe quel pôle",
      _id: "edit_prescriber_globally"
    },
    EDIT_PRESCRIBER_RESTRICTED: {
      label: "Editer un prescripteur uniquement pour un des pôles de l'utilisateur",
      _id: "edit_prescriber_restricted"
    },
    PRESCRIBERS_FILTER_BY_POLE: {
      label: "Filtrer par pôle dans la liste des prescripteur",
      _id: "prescribers_filter_by_pole"
    },
    ARCHIVE_PRESCRIBER: {
      label: "Archiver un prescripteur",
      _id: "archive_prescriber"
    },
    ARCHIVE_PRESCRIBER_GLOBALLY: {
      label: "Archiver un prescripteur pour n'importe quel pôle",
      _id: "archive_prescriber_globally"
    },
    ARCHIVE_PRESCRIBER_RESTRICTED: {
      label: "Archiver un prescripteur uniquement pour un des pôles de l'utilisateur",
      _id: "archive_prescriber_restricted"
    },
    RESET_PASSWORD_PRESCRIBER: {
      label: "Renouveler le mot de passe d'un prescripteur",
      _id: "reset_password_prescriber"
    },
    DISABLE_SESSION_PRESCRIBER: {
      label: "Supprimer les sessions d'un prescripteur",
      _id: "disable_session_prescriber"
    }
  }
};

module.exports.prescribers = prescribers;
module.exports.prescribersRights = mapRights(prescribers);

const prescribersGroups = {
  type: "Regroupement de médecins",
  rights: {
    CREATE_PRESCRIBERS_GROUP: {
      label: "Créer un regroupement de médecins",
      _id: "create_prescribers_group"
    },
    ACCESS_PRESCRIBERS_GROUP: {
      label: "Accéder à un regroupement de médecins",
      _id: "access_prescribers_group"
    },
    EDIT_PRESCRIBERS_GROUP: {
      label: "Editer un regroupement de médecins",
      _id: "edit_prescribers_group"
    },
    ARCHIVE_PRESCRIBERS_GROUP: {
      label: "Archiver un regroupement de médecins",
      _id: "archive_prescribers_group"
    },
    RESET_PASSWORD_PRESCRIBERS_GROUP: {
      label: "Renouveler le mot de passe d'un regroupement de médecins",
      _id: "reset_password_prescribers_group"
    },
    DISABLE_SESSION_PRESCRIBERS_GROUP: {
      label: "Supprimer les sessions d'un regroupement de médecins",
      _id: "disable_session_prescribers_group"
    }
  }
};

module.exports.prescribersGroups = prescribersGroups;
module.exports.prescribersGroupsRights = mapRights(prescribersGroups);

const prescriptionAddresses = {
  type: "Adresses de prescription",
  rights: {
    SYNCHRONIZE_PRESCRIPTION_ADDRESSES: {
      label: "Synchroniser les adresses de prescription",
      _id: "synchronize_prescription_addresses"
    }
  }
};

module.exports.prescriptionAddresses = prescriptionAddresses;
module.exports.prescriptionAddressesRights = mapRights(prescriptionAddresses);

const users = {
  type: "Utilisateurs",
  rights: {
    CREATE_USER: {
      label: "Créer un utilisateur",
      _id: "create_user"
    },
    GET_USER: {
      label: "Accéder à la ressource d'un utilisateur",
      _id: "get_user"
    },
    VIEW_USER: {
      label: "Accèder à la fiche d'un utilisateur",
      _id: "view_user"
    },
    EDIT_USER: {
      label: "Editer un utilisateur",
      _id: "edit_user"
    },
    ARCHIVE_USER: {
      label: "Archiver un utilisateur",
      _id: "archive_user"
    },
    DISABLE_SESSION_USER: {
      label: "Supprimer les sessions d'un utilisateur",
      _id: "disable_session_user"
    },
    RESET_PASSWORD_USER: {
      label: "Renouveler le mot de passe d'un utilisateur",
      _id: "reset_password_user"
    }
  }
};

module.exports.users = users;
module.exports.usersRights = mapRights(users);

const communities = {
  type: "Collectivités",
  rights: {
    CREATE_COMMUNITY: {
      label: "Créer une collectivité",
      _id: "create_community"
    },
    ACCESS_COMMUNITY: {
      label: "Accéder à une collectivité",
      _id: "access_community"
    },
    EDIT_COMMUNITY: {
      label: "Editer une collectivité",
      _id: "edit_community"
    },
    EDIT_COMMUNITY_GLOBALLY: {
      label: "Editer toutes les collectivités",
      _id: "edit_community_globally"
    },
    EDIT_COMMUNITY_RESTRICTED: {
      label: "Editer les collectivités lié au Pôle de l'utilisateur",
      _id: "edit_community_restricted"
    },
    EDIT_STOCK_COMMUNITY: {
      label: "Editer le stock d'une collectivité",
      _id: "edit_stock_community"
    },
    ARCHIVE_COMMUNITY: {
      label: "Archiver une collectivité",
      _id: "archive_community"
    },
    EDIT_MAIN_INTERVENANT: {
      label: "Modifier l'intervenant principal d'une collectivité",
      _id: "edit_main_intervenant"
    },
    SYNCHRONIZE_COMMUNITY: {
      label: "Synchoniser la collectivité avec SAP",
      _id: "synchronize_community"
    }
  }
};

module.exports.communities = communities;
module.exports.communitiesRights = mapRights(communities);

const communitiesGroups = {
  type: "Regroupement de collectivités",
  rights: {
    CREATE_COMMUNITIES_GROUP: {
      label: "Créer un regroupement de collectivité",
      _id: "create_communities_group"
    },
    ACCESS_COMMUNITIES_GROUP: {
      label: "Accéder à un regroupement de collectivité",
      _id: "access_communities_group"
    },
    EDIT_COMMUNITIES_GROUP: {
      label: "Editer un regroupement de collectivité",
      _id: "edit_communities_group"
    },

    ARCHIVE_COMMUNITIES_GROUP: {
      label: "Archiver un regroupement de collectivité",
      _id: "archive_communities_group"
    }
  }
};

module.exports.communitiesGroups = communitiesGroups;
module.exports.communitiesGroupsRights = mapRights(communitiesGroups);

const interventions = {
  type: "Interventions",
  rights: {
    CREATE_INTERVENTION: {
      label: "Créer une intervention",
      _id: "create_intervention"
    },
    ACCESS_INTERVENTION: {
      label: "Accéder à une intervention",
      _id: "access_intervention"
    },
    EDIT_INTERVENTION: {
      label: "Editer une intervention",
      _id: "edit_intervention"
    },
    EDIT_INTERVENTION_RESTRICTED: {
      label: "Editer une intervention uniquement pour un des pôles de l'utilisateur",
      _id: "edit_intervention_restricted"
    },
    EDIT_INTERVENTION_GLOBALLY: {
      label: "Editer une intervention pour n'importe quel pôle",
      _id: "edit_intervention_globally"
    },
    EDIT_FINISHED_INTERVENTION: {
      label: "Editer une intervention finalisée",
      _id: "edit_finished_intervention"
    },
    ARCHIVE_INTERVENTION: {
      label: "Archiver une intervention",
      _id: "archive_intervention"
    },
    DELETE_INTERVENTION_GLOBALLY: {
      label: "Supprimer une intervention non-protocolaire pour n'importe quel pôle",
      _id: "delete_intervention_globally"
    },
    DELETE_INTERVENTION_RESTRICTED: {
      label: "Supprimer une intervention non-protocolaire uniquement pour un des pôles de l'utilisateur",
      _id: "delete_intervention_restricted"
    },
    ACCESS_PLANNED_INTERVENTION: {
      label: "Accéder aux interventions programmées",
      _id: "access_planned_intervention"
    },
    VALIDATE_INTERVENTION: {
      label: "Valider et contrôler une intervention",
      _id: "validate_intervention"
    },
    SIGN_INTERVENTION_BL: {
      label: "Faire signer le bon de livraison d'une intervention",
      _id: "sign_intervention_bl"
    },
    ACCESS_INTERVENTION_ANOMALY: {
      label: "Accèder à l'anomalie de stock d'une intervention",
      _id: "access_intervention_anomaly"
    },
    UPDATE_INTERVENTION_ANOMALY_STATUS: {
      label: "Mettre à jour l'état de l'anomalie de stock d'une intervention",
      _id: "update_intervention_anomaly_status"
    },
    FORCE_UPDATE_INTERVENTION_ANOMALY_STATUS: {
      label: "Forcer la mise à jour de l'état de l'anomalie de stock d'une intervention",
      _id: "force_update_intervention_anomaly_status"
    },
    EDIT_STOCK_ON_ANOMALY: {
      label: "Modifier le contenu d'une livraison dans le cadre d'une anomalie",
      _id: "edit_stock_on_anomaly"
    },
    DELETE_AND_REPLANNED: {
      label: "Supprimer et replannifier une intervention",
      _id: "delete_and_replanned"
    },
    ACCESS_RISK_ANALYSIS: {
      label: "Accéder à l'analyse de risques d'une intervention",
      _id: "access_risk_analysis"
    },
    SYNCHRONIZE_STOCKS: {
      label: "Synchoniser les stocks",
      _id: "synchronize_stocks"
    },
    WRITE_RISK_ANALYSIS: {
      label: "Droit de clotûre et d'écriture du commentaire d'action de l'analyse de risque",
      _id: "write_risk_analysis"
    },
    REMOVE_VALIDATED_INTERVENTION_FILE: {
      label: "Droit de suppression d'un document lié à une intervention validée",
      _id: "remove_validated_intervention_file"
    },
    EDIT_LOCATION_INTERVENTION_FINISHED: {
      label: "Droit d'édition de l'adresse de l'intervention pour intervention validée",
      _id: "edit_location_intervention_finished"
    },
    SYNCHRONIZE_TO_BUFFER_STOCK: {
      label: "Synchroniser le stock tampon",
      _id: "synchronize_to_buffer_stock"
    },
    CHANGE_RESTRICTED_INTERVENTION_TYPE: {
      label: "Changer le type d'une intervention avec type restreint (installation etc.)",
      _id: "change_restricted_intervention_type"
    }
  }
};

module.exports.interventions = interventions;
module.exports.interventionsRights = mapRights(interventions);

const reports = {
  type: "Reports",
  rights: {
    CREATE_REPORT: {
      label: "Accéder à l'ajout d'un compte rendu",
      _id: "create_report"
    },
    ACCESS_VIEW_REPORT: {
      label: "Accéder à la vue d'un compte rendu",
      _id: "access_view_report"
    },
    ACCESS_POLE_REPORT: {
      label: "Accéder au champ pole des filtres du compte rendu",
      _id: "access_pole_report"
    }
  }
};

module.exports.reports = reports;
module.exports.reportsRights = mapRights(reports);

const planManagement = {
  type: "Planification",
  rights: {
    ACCESS_PLAN_MANAGEMENT: {
      label: "Accéder à la planification",
      _id: "access_plan_management"
    },
    EDIT_PLAN_MANAGEMENT: {
      label: "Editer la planification",
      _id: "edit_plan_management"
    }
  }
};

module.exports.planManagement = planManagement;
module.exports.planManagementRights = mapRights(planManagement);

const products = {
  type: "Produits",
  rights: {
    CREATE_PRODUCT: {
      label: "Créer un produit",
      _id: "create_product"
    },
    ACCESS_PRODUCT: {
      label: "Accéder à un produit",
      _id: "access_product"
    },
    EDIT_PRODUCT: {
      label: "Editer un produit",
      _id: "edit_product"
    },
    REMOVE_PRODUCT: {
      label: "Récupérer un produit",
      _id: "remove_product"
    },
    ARCHIVE_PRODUCT: {
      label: "Archiver un produit",
      _id: "archive_product"
    },
    ACCESS_GAS_OXYGEN_MONITORING: {
      label: "Accéder au monitoring des bouteilles d'oxygène gazeux",
      _id: "access_gas_oxygen_monitoring"
    }
  }
};

module.exports.products = products;
module.exports.productsRights = mapRights(products);

const deliveries = {
  type: "Livraisons de matériel",
  rights: {
    SEE_DELIVERY: {
      label: "Voir les lignes de livraisons d'un contrat",
      _id: "see_delivery"
    },
    CREATE_DELIVERY: { label: "Créer une ligne de livraison", _id: "create_delivery" },
    EDIT_DELIVERY: { label: "Modifier une ligne de livraison", _id: "edit_delivery" },
    PAIR_EQUIPMENT: { label: "Appairer / désappairer un dispositif médical", _id: "pair_equipment" },
    CANCEL_REMOVAL: {
      label: "Annuler la demande de récupération d'une machine",
      _id: "cancel_removal"
    }
  }
};

module.exports.deliveries = deliveries;
module.exports.deliveriesRights = mapRights(deliveries);

const tours = {
  type: "Tournées",
  rights: {
    ACCESS_TOUR: {
      label: "Accéder à une tournée",
      _id: "access_tour"
    }
  }
};

module.exports.tours = tours;
module.exports.toursRights = mapRights(tours);

const administrative = {
  type: "Administratif",
  rights: {
    ACCESS_ADMINISTRATIVE_INFO: {
      label: "Accéder aux informations administratives",
      _id: "access_administrative_info"
    }
  }
};

module.exports.administrative = administrative;
module.exports.administrativeRights = mapRights(administrative);

const menu = {
  type: "menu",
  rights: {
    VIEW_MENU_INTERVENTIONS_TOURNEES: {
      label: "Accèder à l'item de menu 'Interventions et tournées'",
      _id: "view_menu_interventions_tournees"
    },
    VIEW_MENU_MON_TRAVAIL: {
      label: "Accèder à l'item de menu 'Mon travail'",
      _id: "view_menu_mon_travail"
    },
    VIEW_MENU_ANOMALY_INTERVENTIONS: {
      label: "Accèder au sous item de menu 'Interventions avec une anomalie'",
      _id: "view_menu_anomaly_interventions"
    },
    VIEW_MENU_TO_BE_PREPARED_INTERVENTIONS: {
      label: "Accèder au sous item de menu 'Interventions à préparer'",
      _id: "view_menu_to_be_prepared_interventions"
    },
    VIEW_MENU_ENTITY_INTERVENTIONS: {
      label: "Accèder à l'item de menu 'Livraisons en collectivité'",
      _id: "view_menu_entity_interventions"
    },
    VIEW_MENU_ANOMALY_ENTITY_INTERVENTIONS: {
      label: "Accèder au sous item de menu 'Livraisons en collectivité avec une anomalie'",
      _id: "view_menu_anomaly_entity_interventions"
    },
    VIEW_MENU_SEARCH_PATIENT: {
      label: "Accèder à l'item de menu 'Mon travail'",
      _id: "view_menu_search_patient"
    },
    VIEW_MENU_EXPORT_MOUVEMENTS_STOCK: {
      label: "Accèder à l'item de menu 'Export des mouvements de stock'",
      _id: "view_menu_export_mouvements_stock"
    },
    VIEW_MENU_EXPORT_DELIVERED_PAIRABLE_DEVICES: {
      label: "Accèder à l'item de menu 'Export des appairages'",
      _id: "view_menu_export_delivered_pairable_devices"
    },
    VIEW_MENU_EXPORT_CONSOMMATIONS: {
      label: "Accèder à l'item de menu 'Export des estimations de consommations'",
      _id: "view_menu_export_consommations"
    },
    VIEW_MENU_STOCK_POLE: {
      label: "Accèder à l'item de menu 'Stock du pole'",
      _id: "view_menu_stock_pole"
    },
    VIEW_MENU_REPORT: {
      label: "Accèder au menu des comptes-rendus d'activités",
      _id: "view_menu_report"
    }
  }
};

module.exports.menu = menu;
module.exports.menuRights = mapRights(menu);

const documents = {
  type: "Documents",
  rights: {
    VIEW_DOCUMENTS: {
      label: "Accèder à la base documentaire",
      _id: "view_documents"
    }
  }
};

module.exports.documents = documents;
module.exports.documentsRights = mapRights(documents);

const userEvents = {
  type: "Evènements utilisateurs",
  rights: {
    CREATE_USER_EVENTS: {
      label: "Créer un événement utilisateur",
      _id: "create_user_events"
    },
    ACCESS_USER_EVENTS: {
      label: "Accéder à un événement utilisateur",
      _id: "access_user_events"
    },
    EDIT_USER_EVENTS: {
      label: "Editer un événement utilisateur",
      _id: "edit_user_events"
    },
    DELETE_USER_EVENTS: {
      label: "Supprimer un événement utilisateur",
      _id: "delete_user_events"
    }
  }
};
module.exports.userEvents = userEvents;
module.exports.userEventsRights = mapRights(userEvents);

const supportTickets = {
  type: "Tickets de support",
  rights: {
    ACCESS_SUPPORT_TICKETS: {
      label: "Accéder aux tickets de support",
      _id: "access_support_tickets"
    },
    CREATE_SUPPORT_TICKETS_GIT: {
      label: "Créer un ticket de support sur GitHub",
      _id: "create_support_tickets_git"
    },
    CREATE_SUPPORT_TICKETS: {
      label: "Créer un ticket de support",
      _id: "create_support_tickets"
    }
  }
};
module.exports.supportTickets = supportTickets;
module.exports.supportTicketsRights = mapRights(supportTickets);

const supportLogs = {
  type: "Logs de support",
  rights: {
    ACCESS_SUPPORT_LOGS: {
      label: "Accéder aux logs",
      _id: "access_support_logs"
    }
  }
};

module.exports.supportLogs = supportLogs;
module.exports.supportLogsRights = mapRights(supportLogs);

const entityInterventions = {
  type: "Livraisons entité",
  rights: {
    CREATE_ENTITY_INTERVENTION: {
      label: "Créer une livraison entité",
      _id: "create_entity_intervention"
    },
    ACCESS_ENTITY_INTERVENTION: {
      label: "Accéder à une livraison entité",
      _id: "access_entity_intervention"
    },
    EDIT_ENTITY_INTERVENTION: {
      label: "Editer une livraison entité",
      _id: "edit_entity_intervention"
    },
    ARCHIVE_ENTITY_INTERVENTION: {
      label: "Archiver une livraison entité",
      _id: "archive_entity_intervention"
    },
    VALIDATE_ENTITY_INTERVENTION: {
      label: "Valider et contrôler une livraison entité",
      _id: "validate_entity_intervention"
    },
    ACCESS_ENTITY_INTERVENTION_ANOMALY: {
      label: "Accèder à l'anomalie de stock d'une livraison entité",
      _id: "access_entity_intervention_anomaly"
    },
    EDIT_STOCK_ON_ENTITY_INTERVENTION_ANOMALY: {
      label: "Modifier le contenu d'une livraison dans le cadre d'une anomalie sur une livraison chez une entité",
      _id: "edit_stock_on_entity_intervention_anomaly"
    },
    DELETE_ENTITY_INTERVENTION_AND_REPLANNED: {
      label: "Supprimer et relancer la planification de la livraison en collectivités",
      _id: "delete_entity_intervention_and_replanned"
    }
  }
};

module.exports.entityInterventions = entityInterventions;
module.exports.entityInterventionsRights = mapRights(entityInterventions);

module.exports.supportLogs = supportLogs;
module.exports.supportLogsRights = mapRights(supportLogs);

const noticesManager = {
  type: "Notice Manager",
  rights: {
    CREATE_NOTICE: {
      label: "Créer une notice",
      _id: "create_notice"
    },
    EDIT_NOTICE: {
      label: "Editer une notice",
      _id: "edit_notice"
    },
    ACCESS_NOTICES_MANAGER: {
      label: "Accéder à la liste des notices",
      _id: "access_notices_manager"
    }
  }
};

module.exports.noticesManager = noticesManager;
module.exports.noticesManagerRights = mapRights(noticesManager);

const synchronization = {
  type: "Synchronization",
  rights: {
    ACCESS_SYNCHRO_STATUS: {
      label: "Accéder aux status de synchronisation",
      _id: "access_synchronization_statuses"
    }
  }
};
module.exports.synchronization = synchronization;
module.exports.synchronizationRights = mapRights(synchronization);

const publications = {
  type: "Prescriber Publications",
  rights: {
    ADD_PRESCRIBER_BANNER: {
      label: "Créer un bandeau d'information",
      _id: "add_prescriber_banner"
    },
    ADD_PRESCRIBER_NEWS: {
      label: "Créer une actualité",
      _id: "add_prescriber_news"
    },
    ACCESS_PUBLICATIONS: {
      label: "Liste des publications",
      _id: "access_publications"
    }
  }
};
module.exports.publications = publications;
module.exports.publicationsRights = mapRights(publications);

const stocks = {
  type: "Stocks",
  rights: {
    CREATE_STOCK_MOVEMENT: {
      label: "Créer un mouvement de stock",
      _id: "create_stock_movement"
    }
  }
};

module.exports.stocks = stocks;
module.exports.stocksRights = mapRights(stocks);

const productsReminders = {
  type: "Products Reminders",
  rights: {
    ACCESS_PRODUCTS_REMINDERS: {
      label: "Accèder à la base produit",
      _id: "access_products_reminders"
    },
    CREATE_PRODUCTS_REMINDER: {
      label: "Créer une base produit",
      _id: "create_products_reminder"
    }
  }
};

module.exports.productsReminders = productsReminders;
module.exports.productsRemindersRights = mapRights(productsReminders);

const customProtocols = {
  type: "Custom Protocols",
  rights: {
    ACCESS_CUSTOM_PROTOCOLS: {
      label: "Accéder à un custom protocol",
      _id: "access_custom_protocols"
    },
    CREATE_CUSTOM_PROTOCOLS: {
      label: "Créer un custom protocol",
      _id: "create_custom_protocols"
    }
  }
};

module.exports.customProtocols = customProtocols;
module.exports.customProtocolsRights = mapRights(customProtocols);

const contacts = {
  type: "Diabete Contacts",
  rights: {
    ACCESS_CONTACTS: {
      label: "Liste des contacts",
      _id: "access_contacts"
    },
    CREATE_CONTACT: {
      label: "Ajout d'un contact",
      _id: "create_contact"
    },
    ARCHIVE_CONTACT: {
      label: "Archivage d'un contact",
      _id: "archive_contact"
    },
    EDIT_CONTACT: {
      label: "Edition d'un contact",
      _id: "edit_contact"
    }
  }
};

module.exports.contacts = contacts;
module.exports.contactsRights = mapRights(contacts);

const reminders = {
  type: "Reminders",
  rights: {
    ACCESS_REMINDERS: {
      label: "Liste des rappels",
      _id: "access_reminders"
    },
    CREATE_REMINDER: {
      label: "Créer un rappel",
      _id: "create_reminder"
    }
  }
};

module.exports.reminders = reminders;
module.exports.remindersRights = mapRights(reminders);

const faxes = {
  type: "Faxes",
  rights: {
    CREATE_FAX: {
      label: "Créer un fax",
      _id: "create_fax"
    }
  }
};

module.exports.faxes = faxes;
module.exports.faxesRights = mapRights(faxes);

const maintenances = {
  type: "Maintenances",
  rights: {
    ACCESS_MAINTENANCES: {
      label: "Accèder aux avis de maintenances",
      _id: "access_maintenances"
    }
  }
};

module.exports.maintenances = maintenances;
module.exports.maintenancesRights = mapRights(maintenances);
